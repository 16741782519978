<template>
  <div class="container-fluid px-4" style="max-width: 1300px">
    <div class="row h-100" v-if="loading">
      <div class="col-12 h-100 text-center" style>
        <div class="w-100 py-5"></div>
        <b-spinner type="grow" label="Spinning"></b-spinner>
        <div class="w-100"></div>
        <span>Cargando...</span>
      </div>
    </div>

    <div class="row">
      <nav
        class="navbar sticky-top border-bottom nav-categories py-0 w-100"
        :class="getThemeBody()"
        id="nav-categories"
      >
        <div class="slider position-relative1 w-100" ref="scroll-cat" id="scroll-categories">
          <div
            class="px-3 item"
            v-for="cat in categories"
            :key="cat._id"
            :ref="`cat-${cat._id}`"
            style="border-bottom-width: 2px !important"
            :class="{
              'border-bottom border-dark': cid == cat._id,
            }"
          >
            <a
              :class="{
                active: cid == cat._id,
              }"
              @click="setCategory(cat)"
              class="item text-nowrap text-uppercase click"
              >{{ cat.name }}</a
            >
          </div>
          <div
            class="px-1 item bg-light position-absolute"
            style="!important; right: 0; height: 100%"
            id="cart-button-scroll-categories"
          >
            <router-link
              class="btn text-center font-weight-bold align-bottom mt-1"
              :to="getLink('pedido')"
            >
              <b-icon
                icon="cart-fill"
                class="
                  text-dark
                  pr-1
                  animate__animated
                  animate__tada
                  animate__delay
                  animate__infinite
                "
              ></b-icon>
              <span class="text-danger ml-1">${{ formatNumber(total) }}</span>
            </router-link>
          </div>
        </div>
      </nav>
      <div class="w-100">
        <section
          class="section"
          v-if="settings.showRecomended && recomendedList.length > 0"
        >
          <div class="w-100 d-block pb-5"></div>
          <h3
            class="
              mt-3
              text-uppercase
              border-bottom
              font-weight-bolder
              text-center
              pb-3
            "
          >
            RECOMENDADOS
            <div
              class="
                badge
                animate__animated animate__tada animate__infinite
                infinite
              "
            >
              <b-icon icon="star-fill" class="text-warning"></b-icon>
            </div>
          </h3>
          <div class="row">
            <div
              class="col-md-6 col-lg-6 col-xl-4 col-12 click py-1"
              @click="viewProduct(p._id, p.name)"
              v-for="p in recomendedList"
              :key="p._id"
            >
              <div
                class="card justify-content-center card-product"
                style="overflow-x: hidden"
              >
                <div class="row no-gutters">
                  <div class="col-md-4 p-0 col-5 text-center overflow-hidden">
                    <!-- <b-img-lazy :src="getImage(p.image)"></b-img-lazy> -->

                    <svg
                      class="bd-placeholder-img"
                      width="100%"
                      height="180"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder: Image"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#6c757d"></rect>
                      <text x="30%" y="50%" fill="#dee2e6" dy=".4em">
                        Cargando...
                      </text>
                    </svg>
                    <img
                      :src="getImage(p.image, 'small')"
                      class="position-absolute top-0 right-0 h-100 w-100"
                      loading="lazy"
                      style="min-width: 100%; right: 0; object-fit: cover"
                    />
                  </div>
                  <div class="col-md-8 col p-2">
                    <div class="card-body px-2 py-1">
                      <span
                        class="card-title text-uppercase font-weight-bold"
                        >{{ p.name }}</span
                      >
                      <div class="w-100"></div>
                      <div class="badge badge-danger badge-pill">
                        <span style="font-size: 12px">
                          ${{ formatNumber(p.value) }}
                        </span>
                      </div>
                      <div class="w-100 py-1"></div>
                      <small
                        class="d-block pr-2 mt"
                        v-if="p.description"
                        style="white-space: normal; font-weight: 500"
                      >
                        {{ p.description.slice(0, 60) }}
                        <span v-if="p.description.length > 60">...</span>
                      </small>
                      <small class="text-muted" v-else>Sin descripcion</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="section"
          v-if="
            settings.showMostSoldProducts && listProductsMostSelled.length > 0
          "
        >
          <div class="w-100 d-block pb-5"></div>
          <h1
            class="
              mt-3
              text-uppercase
              border-bottom
              font-weight-bolder
              text-center
              pb-3
              fs-3
            "
          >
            MAS VENDIDOS
            <div
              class="
                badge badge-danger
                animate__animated animate__tada animate__infinite
                infinite
              "
            >
              <small>HOT</small>
            </div>
          </h1>
          <div class="row">
            <div
              class="col-md-6 col-lg-6 col-xl-4 col-12 click py-1"
              @click="viewProduct(p._id, p.name)"
              v-for="p in listProductsMostSelled"
              :key="p._id"
            >
              <div
                class="card justify-content-center card-product"
                style="overflow-x: hidden"
              >
                <div class="row no-gutters">
                  <div class="col-md-4 p-0 col-5 text-center overflow-hidden">
                    <!-- <b-img-lazy :src="getImage(p.image)"></b-img-lazy> -->

                    <svg
                      class="bd-placeholder-img"
                      width="100%"
                      height="180"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder: Image"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#6c757d"></rect>
                      <text x="30%" y="50%" fill="#dee2e6" dy=".4em">
                        Cargando...
                      </text>
                    </svg>
                    <img
                      :src="getImage(p.image, 'small')"
                      class="position-absolute top-0 right-0 h-100 w-100"
                      loading="lazy"
                      style="min-width: 100%; right: 0; object-fit: cover"
                    />
                  </div>
                  <div class="col-md-8 col p-2">
                    <div class="card-body px-2 py-1">
                      <span
                        class="card-title text-uppercase font-weight-bold"
                        >{{ p.name }}</span
                      >
                      <div class="w-100"></div>
                      <div class="badge badge-danger badge-pill">
                        <span style="font-size: 12px">
                          ${{ formatNumber(p.value) }}
                        </span>
                      </div>
                      <div class="w-100 py-1"></div>
                      <small
                        class="d-block pr-2 mt"
                        v-if="p.description"
                        style="white-space: normal; font-weight: 500"
                      >
                        {{ p.description.slice(0, 60) }}
                        <span v-if="p.description.length > 60">...</span>
                      </small>
                      <small class="text-muted" v-else>Sin descripcion</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="section w-100"
          v-for="cat in categories"
          :key="cat._id"
          :ref="`fat-${cat._id}`"
          :data-id="cat._id"
        >
          <div class="w-100 d-block pb-5"></div>
          <h1
            class="
              mt-3
              text-uppercase
              border-bottom
              font-weight-bolder
              text-center
              pb-3
              fs-3
            "
          >
            {{ cat.name }}
          </h1>
          <div class="row">
            <div
              class="col-md-6 col-lg-6 col-xl-4 col-12 click py-1"
              @click="viewProduct(p._id, p.name)"
              v-for="p in listProducts(cat)"
              :key="p._id"
            >
              <div
                class="card justify-content-center card-product"
                style="overflow-x: hidden"
              >
                <div class="row no-gutters">
                  <div
                    class="col-md-4 p-0 col-5 text-center overflow-hidden"
                    v-if="showProductImage(p.image)"
                  >
                    <!-- <b-img-lazy :src="getImage(p.image)"></b-img-lazy> -->

                    <svg
                      class="bd-placeholder-img"
                      width="100%"
                      height="150"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder: Image"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#6c757d"></rect>
                      <text x="20%" y="50%" fill="#dee2e6" dy=".2em">
                        Cargando...
                      </text>
                    </svg>

                    <img
                      :src="getImage(p.image, 'small')"
                      class="position-absolute top-0 right-0 h-100 w-100"
                      loading="lazy"
                      style="min-width: 100%; right: 0; object-fit: cover"
                    />
                  </div>
                  <div class="col-md col p-2">
                    <div class="card-body px-2 py-1">
                      <span
                        class="card-title text-uppercase font-weight-bold"
                        >{{ p.name }}</span
                      >
                      <div
                        v-if="getPromos(p)"
                        class="
                          badge badge-warning
                          animate__animated animate__tada animate__infinite
                          infinite
                          position-absolute
                        "
                        style="top: 5px; right: 5px; z-index: 1"
                      >
                        <span class="fs-2">{{getPromos(p)}}</span>
                      </div>
                      <div class="w-100"></div>
                      <div class="badge badge-danger badge-pill">
                        <span style="font-size: 12px">
                          ${{ formatNumber(p.value) }}
                        </span>
                      </div>
                      <div class="w-100 py-1"></div>
                      <small
                        class="d-block pr-2 mt"
                        v-if="p.description"
                        style="white-space: normal; font-weight: 500"
                      >
                        {{ p.description.slice(0, 60) }}
                        <span v-if="p.description.length > 60">...</span>
                      </small>
                      <small class="text-muted" v-else>Sin descripcion</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      products: [],
      category: {},
      pid: null,
      loading: true,
      cid: "",
      listProductsMostSelled: [],
      productName: "Cargando...",
      page: {},
      recomendedList: [],
    };
  },
  computed: {
    lastscroll() {
      return this.$store.state.lastscroll;
    },
    total() {
      return this.$store.state.products.reduce((t, a) => t + a.total, 0);
    },
    categories() {
      return this.$store.state.categories;
    },
    info() {
      return this.$store.state.info;
    },
    settings() {
      return this.info.settings;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scroll);
  },
  mounted() {
    window.scroll(0, 0);
    document.title = `${this.info.custom_name || this.info.name}`;
    this.load();

    setTimeout(() => {
      const scrollContainer = document.querySelector(".slider");

      scrollContainer.addEventListener(
        "wheel",
        (evt) => {
          evt.preventDefault();
          scrollContainer.scrollLeft += evt.deltaY;
        },
        { passive: true }
      );

      window.addEventListener("scroll", this.scroll, { passive: true });
    }, 3000);

    // this.$nextTick(() => {
    //   const tour = this.$shepherd({
    //     useModalOverlay: true,
    //   });

    //   tour.addStep({
    //     attachTo: { element: '.', on: "top" },
    //     text: "Categorias",
    //     buttons: [
    //       {
    //         text: "Next",
    //         action: tour.next,
    //       },
    //     ],
    //   });

    //   tour.start();
    // });
  },
  methods: {
     getPromos(product) {

      const promos = product.promos
      const value = product.value;

      if(!promos) return false

      const enabled = promos.filter(p => p.enabled)

      let rs = "";

      //console.log({ promos, enabled });

      if (enabled) {
        enabled.forEach((promo) => {
          rs += this.getPromo(promo, value, true);
        });
      }

      return rs;
    },
    /* eslint-disable no-unused-vars */
    showProductImage(image = null) {
      if (this.settings.productImage == "hide") return false;
      if (this.settings.productImage == "isvalid" && image == "") return false;
      return true;
    },
    updateProductData(data) {
      console.log("update product data");
      this.productName = data;
    },
    scroll(e) {
      if (Date.now() - this.lastscroll > 2500) {
        console.log(e);
        let currentid;
        let index;
        let sections = document.getElementsByClassName("section");
        let scrollTop = window.pageYOffset + 100;

        for (let i = 0; i < sections.length; i++) {
          let sHeight = sections[i].offsetHeight;
          let offSet = sections[i].offsetTop;
          let { id } = sections[i].dataset;
          //const [el] = this.$refs[`cat-${id}`];

          if (scrollTop > offSet && scrollTop < offSet + sHeight) {
            console.log("section active", id);
            currentid = id;
            index = i;
          }
        }
        // console.log(sections)
        if (currentid) {
          this.cid = currentid;
          console.log(index);
          //  let items = document.querySelectorAll("a.item");
          // items[index].scrollIntoView({
          //   inline: "center",
          //   behavior: "smooth",
          // });
          // this.$router.push({ hash: currentid });
        }
        this.$store.commit("setLastscroll", Date.now());
      }
    },
    closeModal() {
      this.$refs.modal.hide();
      // window.scrollTo(0, 0);
    },
    listProducts(cat) {
      let cid = cat._id;
      return this.products.filter((p) => {
        if (p.category && p.category._id == cid) return p;
        if (p.category == cid) return p;
      });
    },
    getCategory(p) {
      if (p.category && p.category.name) return p.category.name;
      return "Sin Categoria";
    },
    load() {
      const id = this.$store.state.info.webName;
      console.log("id", id);
      if (!id || id == undefined) return false;
      this.$http.get(id + "/category").then((res) => {
        //this.categories = res.data.data;
        this.$store.commit("setCategories", res.data.data);
      });
      let query = "";
      if (this.category && this.category._id) {
        query = `category=${this.category._id}`;
      }
      this.$http.get(`${id}/product?${query}`).then((res) => {
        this.products = res.data.data;
        this.loading = false;
      });

      if (this.settings.showMostSoldProducts)
        this.$http.get(`${id}/product/mostselled`).then((res) => {
          this.listProductsMostSelled = res.data.data;
        });

      if (this.settings.showRecomended)
        this.$http.get(`${id}/product/recomended`).then((res) => {
          this.recomendedList = res.data.data;
        });

      let page = this.info.pages.find((p) => {
        if (p.slug == "menu") return p;
      });
      if (!page) {
        if (this.info.pages && this.info.pages.length >= 1) {
          let [firstPage] = this.info.pages;
          this.$router.push(this.getLink(firstPage.slug));
        }
        // this.$router.push(`/${this.info.webName}`);
        return false;
      }

      this.$http
        .get(`${id}/page/${page.slug}`)
        .then((res) => {
          this.page = res.data.data;
          document.title = `${this.page.title} - ${
            this.info.custom_name || this.info.name
          }`;
        })
        .catch(() => {
          //this.$router.push(this.getLink("404"));
          console.error("Error al cargar la pagina");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setCategory(cat) {
      this.cid = cat._id;
      this.$store.commit("setLastscroll", Date.now());
      this.$gtag.event("category_click", {
        event_category: "product",
        event_label: "User click category",
        value: cat._id,
      });

      setTimeout(() => {
        const [el] = this.$refs[`fat-${cat._id}`];
        if (el) el.scrollIntoView({ behavior: "smooth" });
      }, 300);
      event.target.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });

      // clear spaces from category name and lowercase
      // let catName = cat.name.replace(/\s/g, "-").toLowerCase();

      //this.$router.push({ hash: catName });

      // this.load();
    },
    viewProduct(pid, pname = "") {
      //const { info } = this.$store.state;
      //this.$router.push(`/${info.webName}/p/${pid}`);
      // this.pid = pid;
      //if (pname) this.productName = pname;
      //this.$refs.modal.show();
      this.$router.push({
        query: {
          pid: pid,
          producto: pname,
        },
      });
      this.$gtag.event("product_view", {
        event_category: "product",
        event_label: "User view product",
        value: 1,
      });
      if (this.settings.facebookPixelEnabled && this.$analytics) {
        console.log("track view content");
        this.$analytics.fbq("track", "ViewContent", {
          pid: this.pid,
          content_name: pname,
        });
      }
    },
  },
  watch: {
    loading() {
      if (!this.loading) {
        const scrolLCat = () => {
          const cid = this.$route.query.c;
          if (cid) {
            const [el] = this.$refs[`fat-${cid}`];
            el.scrollIntoView({ behavior: "smooth" });
          }
        };

        setTimeout(() => {
          scrolLCat();
        }, 1000);
      }
    },
    info() {
      this.load();
    },
  },
};
</script>

<style scoped>
.card-product {
  transition: all 0.5s;
  box-shadow: 0 0.4rem 1rem rgb(0 0 0 / 15%) !important;
}
.card-product:hover {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 35%) !important;
}
.slider::-webkit-scrollbar {
  height: 5px;
}
.slider::-webkit-scrollbar-track {
  margin: 0 0px;
}
.slider::-webkit-scrollbar-thumb {
  background: rgba(213, 215, 217, 0.521);
  border-radius: 10px;
  padding: 0 3px;
}
.slider {
  display: flex;
  max-width: 100%;
  height: 55px;
  line-height: 45px;
  padding-right: 70px;
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-snap-type: x proximity;
  scroll-behavior: smooth;
  scrollbar-width: thin;
}
.item {
  width: auto;
  min-width: auto;
  text-align: center;
  padding: 0 0px;
  scroll-snap-align: center;
  font-weight: 600;
}
.item.active {
  font-weight: bolder;
}
.nav-categories1 {
  background: rgb(20 20 20 / 90%);
  color: white;
}
</style>